body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.marquee {
  color: #252b31;
  background-color: #fff;
  padding: 2px;
  overflow: hidden;
}

.marquee p {
  margin-bottom: 0.2rem;
}

.marquee .prices {
  width: 250px;
  padding: 0 20px;
  border-right: 1px solid #cfcfcf;
  border-left: 1px solid #cfcfcf;
}

.certificate-popups {
  width: 625px
}

.onStickyMarquee {
  width: 100%;
}

.marqueeFixed {
  width: 100%;
  position: fixed;
  background: #fff;
  top: 85px;
  z-index: 1;
}

.certificate-image {
  margin: 0.5vh;
  width: 570px;
  height: 700px
}

.tradeImac {
  margin-left: -5rem;
  scale: 1.5;
}

.storeButton:hover {
  scale: 1.1;
}

.storeImage {
  width: 10rem !important;
}

.webtrader-btn {
  width: 340px;
  display: inline-flex;
  justify-content: center;
}

.trader-main {
  margin-bottom: -4rem !important;
}
.wtButton{
  display: flex;
  justify-content: center;
}

@media screen and (max-width: 580px) {
  .certificate-image {
    margin: 0.5vh;
    width: 89vw;
    height: 60vh
  }

  .certificate-popups {
    width: 96vw
  }

  .tradeImac {
    margin-left: 0;
    margin-bottom: 2rem;
  }
  /* .wtButton{
    display: block;
  } */
  .storeButton{
    margin-bottom: 10px !important;
  }

}

.certificate-button {
  cursor: pointer
}

.certificate-button :hover {
  color: #F27031
}