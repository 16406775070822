.close-popup-button{
    position:fixed;
    top: 120px;
    right: 30px;
    background: none;
    border: 1px solid #fff;
    padding: 5px 15px;
    color:#f27031;
    border-radius: 10px;
}
/* Set the header variable */

/* Set the scroll margin top on all anchor elements by using .anchor class */
/* Note: I'm setting plus 2.5em so the element has some breathing room on the top */
.webtrader-link{
    margin-right: -15px !important;
}
.anchor {
    scroll-margin-top: 200px;
}

@media (max-width:900px) {
    .contact-image{
        text-align: left;
    }
}
@media (min-width:901px) {
    .contact-image{
        text-align: right;
    }
}